import "./styles.css";

const CONFIG = {
  webAppBaseUrl: 'https://app.beady.fun',
  deepLinkBaseUrl: 'fun.beady.consumerapp://',
  defaultTimeout: 5000, // milliseconds
  defaultPath: '/Dashboard'
};

function getQueryParameters () {
  const url = window.location.search;
  const queryString = url.split("?")[1] || url.split("://")[1];
  const urlParams = new URLSearchParams(queryString);

  return {
    path: urlParams.get("path") || CONFIG.defaultPath,
    id: urlParams.get("id") || ""
  };
}

function buildUrl (baseUrl, path, id) {
  const url = new URL(baseUrl);
  url.searchParams.set('path', path);

  if (id) {
    url.searchParams.set('query', JSON.stringify({ pattern: id }));
  }

  return url.toString();
}


function handleRedirect (path, id, timeout = CONFIG.defaultTimeout) {
  // First attempt deep link
  const deepLinkUrl = buildUrl(CONFIG.deepLinkBaseUrl, path, id);
  window.location.replace(deepLinkUrl);

  // Fallback to web app after timeout
  setTimeout(() => {
    const webAppUrl = buildUrl(CONFIG.webAppBaseUrl, path, id);
    window.location = webAppUrl;
  }, timeout);
}

const { path, id } = getQueryParameters();
handleRedirect(path, id, 5000);

document.getElementById("app").innerHTML = `
<h1>Beady app viderestilling...</h1>
<div>
Vi sender dig videre til Beady appen. Hvis det ikke sker automatisk, kan du trykke på linket nedenfor.
  <br /><br />
  <a href="javascript:void(0);" id="manualRedirect" rel="noopener noreferrer">Fortsæt til Beady appen her</a>.
</div>
`;

document
  .getElementById("manualRedirect")
  .addEventListener("click", () => handleRedirect(path, id, 0));
